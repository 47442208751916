/***************************************************************************************************
 * Load `$localize` onto the global scope - used if i18n tags appear in Angular templates.
 */
import '@angular/localize/init';
/**
 * This file includes polyfills needed by Angular and is loaded before the app.
 * You can add your own extra polyfills to this file.
 *
 * This file is divided into 2 sections:
 *   1. Browser polyfills. These are applied before loading ZoneJS and are sorted by browsers.
 *   2. Application imports. Files imported after ZoneJS that should be loaded before your main
 *      file.
 *
 * The current setup is for so-called "evergreen" browsers; the last versions of browsers that
 * automatically update themselves. This includes Safari >= 10, Chrome >= 55 (including Opera),
 * Edge >= 13 on the desktop, and iOS 10 and Chrome on mobile.
 *
 * Learn more in https://angular.io/guide/browser-support
 */

/***************************************************************************************************
* BROWSER POLYFILLS
*/  // Run `npm install --save classlist.js`.  // Run `npm install --save web-animations-js`.


/** Evergreen browsers require these. */
import 'core-js/es6/reflect';
import 'core-js/es6/promise';
import 'core-js/es6/array';
import 'core-js/es7/object';
import 'core-js/es7/array';

/***************************************************************************************************
 * Zone JS is required by Angular itself.
 */
import 'zone.js';  // Included with Angular CLI.

/***************************************************************************************************
 * APPLICATION IMPORTS
 */

/**
 * Date, currency, decimal and percent pipes.
 * Needed for: All but Chrome, Firefox, Edge, IE11 and Safari 10
 */
global.Buffer = global.Buffer || require('buffer').Buffer;

/** Safari and IE string.normalize polyfill */
import 'unorm';

/** IE11 fetch */
import 'isomorphic-fetch';

/** IE11 matches */
if (!Element.prototype.matches) {
  Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
}

(<any> window).BigNumber = require('bignumber.js');

// TODO: Remove when things are fixed upstream (or in the browser?).
// Workaround for Angular issue: https://github.com/angular/angular/issues/35219
// and underlying Chromium bug:https://bugs.chromium.org/p/chromium/issues/detail?id=1049982
// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
(function() {
  const arrayReduce = Array.prototype.reduce;
  let callback;
  Object.defineProperty(Array.prototype, 'reduce', {
      value: function(cb, ...args) {
          callback = cb;

          return arrayReduce.call(this, callback, ...args);
      }
  });
})();
